export const INCENTIVE_GAMES = 'Incentive_Games';

export const GAME_PROVIDER = {
    IGT: 'IGT',
    RGS: 'RGS',
    NETENT: 'NETENT',
    EVOLUTION: 'EVOLUTION',
    EVERI: 'EVERI',
    NYX: 'NYX',
    SPIN: 'SPINGAMING',
    EZUGI: 'EZUGI',
    HIFIVE: 'HIGH5',
    REALMS: 'SLINGO',
    INCENTIVE_GAMES: 'INCENTIVE_GAMES',
    DGC: 'DGC',
    ARISTOCRAT: 'ARISTOCRAT',
    PLAYTECH: 'PLAYTECH',
    PRAGMATIC: 'PRAGMATIC',
    AWAGER: 'AWAGER'
};

export const PLATFORM = {
    Web: 'Web',
    Android: 'Android',
    Xsell: 'Xsell',
    Ios: 'iOS',
    MobileWeb: 'mobile_web',
    Native: 'native'
};

export const APPTENTIVE_EVENTS = {
    GAME_LAUNCHED: 'GAME_LAUNCHED',
    GL_SCREEN_LOAD: 'GL Screen Load',
    GW_ERROR: 'Game Window Error Modal'
};
