import { useDispatch } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';

import { getQueryParameter } from '../../helpers/queryparams';
import { showGeneralError } from '../../../redux/actions/app-error';
import { setRegionInvalid, setRegionValid } from '../../../redux/actions/region';
import connectionType from '../../helpers/connectionType';


// validate query params; checking if region is present NOT if it's a valid one
export const useQueryParameterValidationFGP = () => {
    const providerRef = getQueryParameter('providerRef');
    const provider = getQueryParameter('providerName');
    const region = getQueryParameter('region');
    const dispatch = useDispatch();

    if (region && region !== 'undefined') {
        dispatch(setRegionValid());
    } else {
        dispatch(setRegionInvalid());
        dispatch(showGeneralError());
        datadogRum.addError(
            new Error('[FGP] Query param validation: No region'),
            {
                errorTag: 'QUERY_PARAM_VALIDATION',
                connectionType: connectionType()
            },
        );
        return false;
    }

    if (!provider || !providerRef) {



        dispatch(showGeneralError());
        datadogRum.addError(
            new Error('[FGP] Query param validation: No provider'),
            {
                errorTag: 'QUERY_PARAM_VALIDATION',
                connectionType: connectionType()
            },
        );

        return false;
    }

    return true;
};
