import { useSelector } from 'react-redux';
import { useCallback, useContext } from 'preact/hooks';
import Timers from '../../../Timers/Timers';
import LogoIcon from '../../../../assets/icons/GameWindow/LogoIcon';
import Menu from '../Menu/Menu';
import ExitButton from '../ExitButton/ExitButton';
import {
    depositLinkSelector,
    showDepositButtonSelector,
    showSoundButtonSelector
} from '../../../../selectors/game-window';
import WalletBalance from '../WalletBalance/WalletBalance';
import SoundToggle from '../SoundToggle/SoundToggle';
import { GameWindowContext } from '../../GameWindow';
import { useLogTracking } from '../../../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../../../common/hooks/use-user-tracking/types';
import { AddFunds } from '../../../AddFunds/AddFunds';
import { TopBar } from '../SWJ/TopBar/TopBar';

const Panel = ({ isSwjEnabled, jackpotId, position }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);

    const { logTrackingComponent } = useLogTracking();
    const { deviceType, isPortrait, toggleAudio, showGameWindow } = useContext(GameWindowContext);
    const isMenuOpen = useSelector(state => state?.displayMenu?.displayMenu);
    const showDepositButton = useSelector(showDepositButtonSelector);
    const depositLink = useSelector(depositLinkSelector);
    const showSoundButton = useSelector(showSoundButtonSelector);
    const addFundsEnabled = showGameWindow && depositLink;


    const sendGtmMessage = useCallback(() => {
        logTrackingComponent({
            [getAmplitudeKey('EVENT')]: 'In Game Exited',
            [getAmplitudeKey('MODULE')]: MODULES.IN_GAME_HEADER,
            [getAmplitudeKey('ZONE_NAME')]: ZONES.IN_GAME
        });
    }, [logTrackingComponent]);

    const timer = (
        <Timers
            isPortrait={isPortrait}
            menu={isMenuOpen}
            isLandscape={deviceType === 'landscape' || deviceType === 'desktop'}
        />
    );

    const logo = <LogoIcon isPortrait={isPortrait} isMenuOpen={isMenuOpen} isSwjEnabled={isSwjEnabled} />;
    const verticalLine = showSoundButton ? <div className="vertical-divider" /> : <></>;
    const sound = showSoundButton ? (
        <SoundToggle
            {...{
                toggleAudio,
                width: deviceType === 'desktop' ? '30' : '27',
                height: deviceType === 'desktop' ? '22' : '20'
            }}
        />
    ) : (
        <></>
    );
    const mobileMenuItems = (
        <>
            <span>{<WalletBalance deviceType={deviceType} />}</span>
            {sound}
            {verticalLine}
        </>
    );

    const swjPanel = () => {
        return (
            isSwjEnabled && jackpotId ?
            <>
                <div className={`items-${isMenuOpen ? 'menu-open' : 'menu-closed'}`}>
                    {jackpotId && <TopBar jackpotId={jackpotId} display={!isMenuOpen} />}
                </div>
            </> :
            null
        );

    }

    const render = () => {
        switch (position) {
            case 'top':
                return (
                    <>
                        {showGameWindow && <Menu />}
                        <div className={`items-${isMenuOpen ? 'menu-open' : 'menu-closed'}`}>
                            {isMenuOpen && mobileMenuItems}
                            {isSwjEnabled ?
                            <TopBar jackpotId={jackpotId} display={!isMenuOpen} />
                            : logo
                        }
                        </div>

                        <ExitButton direction={'row'} size={'17'} onClick={sendGtmMessage} zoneName={ZONES.IN_GAME} />
                    </>
                );
            case 'top swj':
                return (swjPanel());
            case 'left':
                return (
                    <>
                        {showGameWindow && <Menu />}
                        {timer}
                    </>
                );
            case 'right':
                return (
                    !isMenuOpen && (
                        <>
                            <ExitButton
                                direction={'column'}
                                size={'21'}
                                onClick={sendGtmMessage}
                                zoneName={ZONES.IN_GAME}
                            />
                            {addFundsEnabled && (
                                <AddFunds
                                    deviceType={deviceType}
                                    showDepositButton={showDepositButton}
                                    depositLink={depositLink}
                                    wrapperClassName="add-funds-wrapper landscape"
                                />
                            )}
                        </>
                    )
                );
            case 'bottom':
                return (
                    !isMenuOpen && (
                        <>
                            {isSwjEnabled && logo}
                            {timer}
                            {addFundsEnabled && (
                                <AddFunds
                                    deviceType={deviceType}
                                    showDepositButton={showDepositButton}
                                    depositLink={depositLink}
                                    wrapperClassName="add-funds-wrapper portrait"
                                />
                            )}
                        </>
                    )
                );
        }
    };

    return <div className={`panel-${position} ${isMenuOpen ? 'menu-active' : ''}`}>{render()}</div>;
};

export default Panel;
