import { useEffect, useMemo, useState, useCallback } from 'preact/hooks';
import { Fragment } from 'preact';
import logo from '../../../../../assets/icons/SWJ/swj-logo.png';
import { JackpotsField } from '../JackpotsField/JackpotsField';
import { TCPopup } from '../TCPopup/TCPopup';
import { useDispatch, useSelector } from 'react-redux';
import { jackpotFeed, MDS, OptInManager } from '@fanduel/casino-swj-consumer';
import { getFgpEndpoint } from '../../../../../common/helpers/get-fgp-endpoint';
import { JackpotWinScreen } from '../JackpotWinScreen/JackpotWinScreen';
import {
    swjOptedInTextSelector,
    swjOptedOutTextSelector,
    swjProviderSelector,
    swjTiersSelector
} from '../../../../../selectors/site-wide-jackpot';
import { setJackpotWin } from '../../../../../redux/reducers/site-wide-jackpot/site-wide-jackpot';
import { getMDSEndpoint } from '../../../../../common/helpers/get-mds-endpoint';
import { useGameClientProxy } from '../../../../Launcher/GameClient/hooks/useGameClientProxy';
import { datadogLogs } from '@datadog/browser-logs';
import { providerSelector } from '../../../../../selectors/gameContext';
import { useInject } from 'inversify-hooks';
import { DI_SERVICE } from '../../../../../dependency-injection/constants';

export const TopBar = ({ jackpotId, display }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    //For social proofing uncomment the code with currentMinorIndex
    const dispatch = useDispatch();
    const [getBalanceData] = useInject(DI_SERVICE.balanceData);
    const swjTiers = useSelector(swjTiersSelector);
    const tiersIds = Object.keys(swjTiers);
    const userId = useSelector(state => state?.session?.userId);
    const optInOutText = {
        jackpotTextNotOptedIn: useSelector(swjOptedOutTextSelector),
        jackpotTextOptedIn: useSelector(swjOptedInTextSelector)
    };
    const wonJackpot = useSelector(state => state?.siteWideJackpot?.jackpotWin?.[0]);
    const isSpinInProgress = useSelector(state => state?.gameWindow?.spinInProgress);
    const swjProvider = useSelector(swjProviderSelector);

    const gameProvider = useSelector(providerSelector);

    const { reloadBalance } = useGameClientProxy();

    const [userOptedIn, setUserOptedIn] = useState(-2); // -2: not visible, -1: intermittent state, 1/0: true/false
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [currentMinorIndex, setCurrentMinorIndex] = useState(0);
    const [jackpot, setJackpot] = useState(null);
    const [showTCPopup, setShowTCPopup] = useState(false);

    const numberOfTiers = useMemo(() => jackpot && jackpot?.tiers?.length, [jackpot]);

    const { initSocket } = MDS(getMDSEndpoint(), message => {
        const {
            data: {
                addedMessage: { type, subtype, payload }
            }
        } = message;

        try {
            const jsonPayload = JSON.parse(payload);

            if (type === 'JACKPOT' && subtype === 'WIN') {
                const tier = swjTiers[jsonPayload.jackpotTierId];

                datadogLogs.logger.info('[MDS][FEED]jackpots_messages_listener_receive', {
                    payload: jsonPayload
                });

                dispatch(
                    setJackpotWin({
                        id: jsonPayload.jackpotTierId,
                        amount: jsonPayload.amount,
                        type: tier.shortName,
                        name: tier.name
                    })
                );
            }

            if (type === 'JACKPOT' && subtype === 'OPT_IN_STATUS') {
                const { optedIn, jackpotId: optedJackpot } = jsonPayload;
                if (optedJackpot === jackpotId) {
                    setUserOptedIn(optedIn);
                }
            }
        } catch (e) {
            console.warn('Error parsing MDS message', e);
        }
    });

    const { checkOptedIn, optIn, optOut } = OptInManager(getFgpEndpoint(), 'game_window', {
        callback: setUserOptedIn,
        defaultJackpotId: jackpotId
    });

    const instantiateRotation = useCallback(() => {
        // ----------- this should stay here, it's for social proofing -----------
        // setCurrentMinorIndex(0);
        setCurrentIndex(prev => (prev + 1) % numberOfTiers);
        // setTimeout(() => {
        //     setCurrentMinorIndex(prev => (prev + 1) % 2);
        // }, 2000);
    }, [numberOfTiers]);

    const handleOpenPopup = e => {
        e.stopPropagation();
        !isSpinInProgress && setShowTCPopup(prev => !prev);
    };

    const getOptedInOption = () => {
        return !userOptedIn ? 'out' : 'in';
    };

    const handleOptInToggle = async () => {
        try {
            setUserOptedIn(-1);
            datadogLogs.logger.info(`[MDS][OPTIN]${userOptedIn ? 'jackpots_set_optout_status_initiated' : 'jackpots_set_optin_status_initiated'}`, {
            });
            userOptedIn ? await optOut() : await optIn();
            datadogLogs.logger.info(`[MDS][OPTIN]${userOptedIn ? 'jackpots_set_optout_status_resolved' : 'jackpots_set_optin_status_resolved'}`, {
            });
        } catch (error) {
            console.log(error);
            datadogLogs.logger.error(`[MDS][OPTIN]${userOptedIn ? 'jackpots_set_optout_status_failed' : 'jackpots_set_optin_status_failed'}`,
                Object.assign(
                    {
                        type: `[MDS][OPTIN]${userOptedIn ? 'jackpots_set_optout_status_failed' : 'jackpots_set_optin_status_failed'}`,
                    },
                    error
                )
            );
        }
    };

    const renderLoadingClass = (noDash = true) => {
        if (userOptedIn === -1) {
            return ' loading';
        }

        if (userOptedIn) {
            return `${noDash ? '' : '-'}opted-in`;
        }

        return '';
    };

    const handleJackpotUpdate = jackpots => {
        if (!jackpots || !jackpots.length) {
            return;
        }

        const fetchedJackpot = Object.assign(jackpots.find(j => j.id === jackpotId));

        if (!fetchedJackpot) {
            return;
        }

        fetchedJackpot.tiers = fetchedJackpot.tiers
            .filter(tier => tiersIds.includes(tier.id))
            .sort((a, b) => (a.currentAmount < b.currentAmount ? -1 : 1));

        fetchedJackpot.tiers = fetchedJackpot.tiers.map(tier => {
            return {
                ...tier,
                name: swjTiers[tier.id]?.shortName
            };
        });

        if (!jackpot) {
            setJackpot(fetchedJackpot);
        } else {
            setJackpot(prev => {
                return { ...prev, tiers: [...fetchedJackpot.tiers] };
            });
        }
    };

    const initializeOptIn = () => {
        try {
            datadogLogs.logger.info('[MDS][OPTIN]jackpots_fetch_optin_status_initiated', {
            });
            checkOptedIn();
            datadogLogs.logger.info('[MDS][OPTIN]jackpots_fetch_optin_status_resolved', {
            });
        } catch (error) {
            console.log(error);
            datadogLogs.logger.error('[MDS][OPTIN]jackpots_fetch_optin_status_failed',
                Object.assign(
                    {
                        type: '[MDS][OPTIN]jackpots_fetch_optin_status_failed',
                    },
                    error
                )
            );
        }
    }

    const initializeJackpotFeed = () => {
        try {
            jackpotFeed(getFgpEndpoint(), handleJackpotUpdate, true, 6000, { provider: swjProvider });
        } catch (error) {
            console.log(error);
            datadogLogs.logger.error('[MDS][FEED]jackpots_feed_fetch_failed',
                Object.assign(
                    {
                        type: '[MDS][FEED]jackpots_feed_fetch_failed',
                    },
                    error
                )
            );
        }
    }

    const initializeMDS = () => {
        try {
            datadogLogs.logger.info('[MDS][FEED]jackpots_messages_listener_initiated', {
            });
            userId && initSocket(userId);
            datadogLogs.logger.info('[MDS][FEED]jackpots_messages_listener_completed', {
            });
        } catch (error) {
            console.log(error);
            datadogLogs.logger.error('[MDS][FEED]jackpots_messages_failed_to_init_appsync_client',
                Object.assign(
                    {
                        type: '[MDS][FEED]jackpots_messages_failed_to_init_appsync_client',
                    },
                    error
                )
            );
        }
    }

    useEffect(() => {
        console.log({ isSpinInProgress, spinTimestamp: Date.now() });
        if (!isSpinInProgress) {
            if (gameProvider.toLowerCase() === "nyx" || gameProvider.toLowerCase() === "lnw") {
                dispatch(getBalanceData(false, (balance) => {
                    reloadBalance(balance);
                }));
            } else {
                reloadBalance();
            }
        }
    }, [isSpinInProgress]);

    useEffect(() => {
        if (userId) {
            initializeJackpotFeed();
            initializeOptIn();
            initializeMDS();
        }
    }, [userId]);

    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event?.data?.type === 'GC/JACKPOT_WIN_EVENT') {
                const payload = JSON.parse(event.data.payload.payload);
                const tier = swjTiers[payload.jackpotTierId];
                dispatch(
                    setJackpotWin({
                        id: payload.jackpotTierId,
                        amount: payload.amount,
                        type: tier.shortName,
                        name: tier.name
                    })
                );
            }
        })
    }, []);

    useEffect(() => {
        // if (numberOfTiers) {
        //     instantiateRotation();
        // }

        const intervalId = numberOfTiers
            ? setInterval(() => {
                  instantiateRotation();
              }, 5000)
            : null;

        return () => {
            clearInterval(intervalId);
        };
    }, [instantiateRotation]);

    return (
        <Fragment>
            {!!wonJackpot && <JackpotWinScreen wonJackpot={wonJackpot} />}

            {!!showTCPopup && display && (
                <TCPopup
                    toggleOptIn={handleOptInToggle}
                    userOptedIn={userOptedIn}
                    setShowTCPopup={setShowTCPopup}
                    renderLoadingClass={renderLoadingClass}
                />
            )}

            {jackpot && userOptedIn !== -2 && !!numberOfTiers && (
                <div
                    className={`swj-top-bar-container ${renderLoadingClass()} ${
                        isSpinInProgress ? 'disabled' : 'enabled'
                    }`}
                    style={{
                        visibility: display ? 'visible' : 'hidden',
                        display : display ? 'flex' : 'none'
                     }}
                >
                    <div className="swj-top-bar-mask" />
                    <div
                        className={`swj-top-bar ${isSpinInProgress ? 'disabled' : 'enabled'}`}
                        onClick={handleOpenPopup}
                    >
                        <img className="swj-logo" src={logo} />
                        <div className="swj-content">
                            <JackpotsField tiers={jackpot.tiers} currentIndex={currentIndex} />
                            <span className="swj-message">
                                {userOptedIn
                                    ? `${optInOutText?.jackpotTextOptedIn}`
                                    : optInOutText?.jackpotTextNotOptedIn}{' '}
                            </span>
                        </div>

                        <button
                            disabled={isSpinInProgress}
                            className={`swj-opt-in-button-${getOptedInOption()} ${showTCPopup ? 'show-popup' : ''} ${
                                isSpinInProgress ? 'disabled' : 'enabled'
                            }`}
                            onClick={handleOpenPopup}
                        >
                            {!showTCPopup ? `Opt ${!userOptedIn ? 'in' : 'out'}` : ''}
                            {showTCPopup && <div className="tc-exit-icon" />}
                        </button>
                    </div>
                </div>
            )}
        </Fragment>
    );
};
